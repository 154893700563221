<template>
  <ul
    v-if="songs"
    class="song-list"
  >
    <li
      v-for="(song, i) in songs"
      :key="i"
      :class="[
          'song-list-item clickable',
          { 'is-playing': song.id === activeSongId },
          { 'is-listened-to': !!listenedTo[song.id] }
      ]"
      :style="{
          '--color': song.hex_color
      }"
      @click="$emit('song-click', song)"
      :title="song.id === activeSongId ? 'Currently playing' : 'Play song'"
    >
      <div class="song-list-item__content">
        <div class="song-list-item__index">{{ i+1 }}.</div>
        
        <SongListTitleRow
          :title="song.title"
          :active="song.id === activeSongId"
        />
        <div class="song-list-item__subtitle-row small">
          {{ song.artist }}
        </div>

        <div
          class="song-list-item__listened-indicator"
          :title="`You ${!!listenedTo[song.id] ? 'have' : 'haven\'t'} listened to this song`"
        >
          ✓
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex'
import SongListTitleRow from './SongListTitleRow.vue'

export default {
  components: { SongListTitleRow },
  props: {
    songs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('wavpool', [
      'activeSongId',
      'listenedTo'
    ])
  }
}
</script>