<template>
  <div
    v-if="playlists.length"
    class="row"
  >
    <div
      v-for="(playlist, i) in playlists"
      :key="i"
      class="col-6"
    >
      <div
        class="playlist-card clickable"
        @click="$emit('playlist-click', playlist)"
      >
        <div class="playlist-card__img">
          <img :src="playlist.thumbnail" class="img-fluid">
        </div>
        <div class="playlist-card__meta">
          <h6 v-text="playlist.title" />
          <div
            v-if="playlist.songs.length"
            class="small text-muted"
            v-text="playlist.songs.length === 1 ? '1 Track' : `${playlist.songs.length} Tracks`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    playlists: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.playlist-card {
  margin-bottom: 24px;
}

.playlist-card__img { 
  img {
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 2px 10px -6px #00000082;
  }
}

.playlist-card__meta {
  margin-top: 11px;
  padding: 0 2px;

  h6 {
    margin-bottom: 4px;
    line-height: 1.1;
  }
}
</style>
