<template>
  <div>
    <div style="margin: 0 -5px">
      <!-- Keyword selection steps - 1 -->
      <div>
        <Accordion :expanded="!moodInteracted">
          <div class="keyword-step-title p2" style="padding-top: 0;">
            Select a mood to get started
          </div>
        </Accordion>
        <div class="keyword-list-group">
          <KeywordList
            v-if="keywordGroups.moods && keywordGroups.moods.length"
            :keywords="keywordGroups.moods"
            :active-keywords="makeArray(activeKeywordMap['mood'])"
            @keyword-click="j => toggleActiveKeyword({type: 'mood', keyword: j})"
          />
        </div>
      </div>

      <!-- Keyword selection steps - 2 -->
      <div style="margin-top: -9px"> 
        <div>
          <Accordion :expanded="moodInteracted && !tempoInteracted">
            <div class="keyword-step-title p2">
              Select a tempo
            </div>            
          </Accordion>
          <Accordion :expanded="moodInteracted">
            <div class="keyword-list-group">
              <KeywordList
                v-if="keywordGroups.tempos && keywordGroups.tempos.length"
                :keywords="keywordGroups.tempos"
                :active-keywords="makeArray(activeKeywordMap['tempo'])"
                @keyword-click="j => toggleActiveKeyword({type: 'tempo', keyword: j})"
              />
            </div>
          </Accordion>
        </div>
      </div>

      <!-- Keyword selection steps - 3 -->
      <div style="margin-top: -9px">
        <div>
          <Accordion :expanded="moodInteracted && tempoInteracted && !nonMoodTempoInteracted">
            <div class="keyword-step-title p2">
              Select additional keywords to curate your playlist
            </div>
          </Accordion>
          <Accordion :expanded="moodInteracted && tempoInteracted">
            <div
              v-for="({ type, key }) in keywordLists"
              :key="key"
              class="keyword-list-group"
            >
              <KeywordList
                v-if="keywordGroups[type] && keywordGroups[type].length"
                :keywords="keywordGroups[type]"
                :active-keywords="makeArray(activeKeywordMap[key])"
                @keyword-click="j => toggleActiveKeyword({type: key, keyword: j})"
              />
            </div>
          </Accordion>
        </div>
      </div>
    </div>

    <Accordion :expanded="activeKeywordIds.length > 0">
      <div>
        <transition name="fade-fast">
          <div
            v-show="nonMoodTempoInteracted"
            class="text-center pt-3"
          >
            <span
              class="pill-button pill-button--sm pill-button--outline"
              @click="onResetClick"
            >
              Reset All Keywords
            </span>
          </div>
        </transition>

        <transition name="fade-fast" mode="out-in">
          <div
            v-if="activeKeywordPlaylistSongs.length === 0"
            key="playlist-empty"
            style="margin-top: 60px;"
          >
            <p class="text-center" style="opacity: 0.3;">
              <em>No tracks found</em>
              <br />
              <em>Try selecting different keywords</em>
            </p>
          </div>
          <div
            v-else
            key="playlist-not-empty"
            style="margin-top: 30px;"
          >
            <transition name="fade-fast" mode="out-in">
              <div :key="activeKeywordPlaylistHash">
                <p class="p2 text-center">
                  {{ activeKeywordPlaylistSongs.length === 1 ? '1 Track' : `${activeKeywordPlaylistSongs.length} Tracks` }}
                </p>
                <br />
                <SongList
                  :songs="activeKeywordPlaylistSongs"
                  @song-click="song => $emit('song-click', song)"
                />
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </Accordion>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import KeywordList from './KeywordList'
import SongList from './SongList'
import Accordion from '../../Accordion'

/*
  title: button label
  type: keywordGroups key
  key: activeKeywordMap key
*/
const keywordLists = [
  // { title: 'Moods', type: 'moods', key: 'mood' },
  // { title: 'Tempos', type: 'tempos', key: 'tempo' },
  { title: 'Energy', type: 'energies', key: 'energy' },
  { title: 'Song Form', type: 'song_forms', key: 'song_form' },
  { title: 'Genre', type: 'genres', key: 'genres' }
]

export default {
  components: {
    KeywordList,
    SongList,
    Accordion
  },
  data: () => ({
    keywordLists,
    activeKeywordPlaylistSongs: []
  }),
  created() {
    this.setActiveKeywordFilteredSongs()
  },
  beforeDestroy() {
    this.ResetHighlightedSongs()
  },
  computed: {
    ...mapState('wavpool', {
      songs: 'songs',
      keywordGroups: 'keywordGroups',
      activeKeywordMap: 'customPlaylistActiveKeywordMap',
      moodInteracted: 'customPlaylistMoodInteracted',
      tempoInteracted: 'customPlaylistTempoInteracted',
      nonMoodTempoInteracted: 'customPlaylistNonMoodTempoKeywordInteracted'
    }),
    ...mapGetters('wavpool', {
      activeKeywordIds: 'customPlaylistActiveKeywordIds',
      activeKeywordMood: 'customPlaylistActiveKeywordMood',
      activeKeywordTempo: 'customPlaylistActiveKeywordTempo'
    }),
    activeKeywordPlaylistHash() {
      return this.activeKeywordPlaylistSongs.map(s => s.id).join('') || ''
    },

  },
  methods: {
    ...mapMutations('wavpool', {
      setHighlightedSongs: 'SET_HIGHLIGHTED_SONGS',
      clearKeywords: 'RESET_CUSTOM_PLAYLIST_ACTIVE_KEYWORD_MAP'
    }),
    ...mapActions('wavpool', {
      ResetHighlightedSongs: 'ResetHighlightedSongs',
      toggleActiveKeyword: 'CustomPlaylistToggleKeywordActive',
      reset: 'CustomPlaylistReset'
    }),
    makeArray(val) {
      return Array.isArray(val) ? val : [val]
    },
    setActiveKeywordFilteredSongs() {
      let songs = []
      
      // filter and return the songs that contain *every* active keyword ID
      if (this.activeKeywordIds.length > 0) {
        songs = [...this.songs].filter(s => {
            return this.activeKeywordIds.every(keywordId => s.keywords.includes(keywordId))
          })
      }

      this.activeKeywordPlaylistSongs = songs
      this.setHighlightedSongs(songs)
    },
    onResetClick() {
      this.reset()
      this.$emit('reset')
    }
  },
  watch: {
    activeKeywordMap: {
      deep: true,
      handler: 'setActiveKeywordFilteredSongs'
    }
  }
}
</script>

<style lang="scss" scoped>
.keyword-list-group {
  padding: 9px 11px;
  background-color: black;
  border-radius: 24px;
  
  & + .keyword-list-group {
    margin-top: -9px;
  }
}

.keyword-step-title {
  text-align: center;
  text-transform: none;
  padding: 1.5rem 0 0.5rem;
}
</style>