<template>
  <div class="pool-container"
       v-bind:style="style"
       @mouseup.stop
       @mousedown.stop
       @mousemove.stop>
    
    <PoolHeader
      @globe-click="onPoolHeaderGlobeClick"
      @toggle-playlist-click="playlistOpen = !playlistOpen"
      @wheel.stop
    />

    <div class="pool-gradient-mask" @wheel.stop></div>  

    <div class="pool-canvas-mask" @wheel.stop>
      <transition name="pool-cover">
        <div
          class="pool-cover"
          v-if="showPoolCover"
        >
          <transition name="fade">
            <div v-show="showPoolCoverText">
              Choose a song from a<br /> playlist or keyword
              <div style="margin-top: 5px">
                <svg width="25" height="12" viewBox="0 0 25 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7574 0.696698L24.5303 5.46967C24.8232 5.76256 24.8232 6.23744 24.5303 6.53033L19.7574 11.3033C19.4645 11.5962 18.9896 11.5962 18.6967 11.3033C18.4038 11.0104 18.4038 10.5355 18.6967 10.2426L22.1893 6.75L5.48401e-07 6.75L4.17266e-07 5.25L22.1893 5.25L18.6967 1.75736C18.4038 1.46447 18.4038 0.989592 18.6967 0.696698C18.9896 0.403805 19.4645 0.403805 19.7574 0.696698Z" fill="white"/>
                </svg>
              </div>
            </div>
          </transition>
        </div>
      </transition>

      <PoolCanvas
        @song-selected="onPoolSongSelected"
      />
    </div>

    <transition name="fade">
      <div
        v-show="!searchOverlayOpen"
        class="pool-footer"
      >
        <div class="pool-footer__unit">
          <div class="pill-button-group">
            <div
              v-text="'How-To Guide'"
              class="pill-button pill-button--outline"
              @click.prevent="showHowToGuide = true"
            />

            <router-link
              v-if="isAdmin"
              to="/admin"
              class="pill-button pill-button--outline"
              v-text="'Admin'"
            />
          </div>
        </div>

        <div class="pool-footer__unit">
          <div
            class="pill-button pill-button--outline"
            @click="searchOverlayOpen = !searchOverlayOpen"
          >
            Search
          </div>
        </div>
      </div>
    </transition>

    <!-- Prompt -->
    <transition name="how-to-prompt">
      <div
        v-show="showHowToGuidePrompt"
        class="how-to-prompt"
      >
        <div class="how-to-prompt__text">
          New to<br /> .Wav Pool?
          
          <div style="font-size: 0.875rem; margin-top: 14px;">
            <svg width="14" height="14" viewBox="0 0 37 37" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.5 9C29.5 8.17157 28.8284 7.5 28 7.5H14.5C13.6716 7.5 13 8.17157 13 9C13 9.82843 13.6716 10.5 14.5 10.5L26.5 10.5L26.5 22.5C26.5 23.3284 27.1716 24 28 24C28.8284 24 29.5 23.3284 29.5 22.5V9ZM9.06066 30.0607L29.0607 10.0607L26.9393 7.93934L6.93934 27.9393L9.06066 30.0607Z"
                  fill="currentColor"/>
            </svg>
            Start Here
          </div>
        </div>
        <div class="how-to-prompt__disc-container">
          <div
            class="how-to-prompt__disc"
            @click.prevent="showHowToGuide = true"
          ></div>
        </div>
      </div>
    </transition>    

    <SplashOverlay
      :show="splashOverlayOpen && showSplashOverlay"
      :loaded="splashOverlayLoaded"
      :links="splashOverlayLinks"
      @after-leave="onSplashOverlayAfterLeave"
      @close-click="setSplashOverlayOpen(false)"
      @playlist-click="onSplashOverlayPlaylistClick"
    />

    <PoolPlaylists
      :reduced-height="transportDockVisible /* Reduce the height when the dock is open */"
      :transition-height="transportDockTransitioning"
      @playlist-click="onPlaylistClick"
      @playlist-song-click="onPlaylistSongClick"
      @playlist-deleted="onPlaylistDeleted"
      @tab-change-non-playlist="onPlaylistsTabChangeNonPlaylist"
      @leave="onPlaylistsLeave"
    />

    <TransportDock
      :show="transportDockVisible"
      @click-close="onTransportDockCloseClick"
      @click-add-to-playlist="onTransportDockAddToPlaylistClick"
      @click-download="onTransportDockDownloadClick"
      @transition-start="onTransportDockTransitionStart"
      @transition-end="onTransportDockTransitionEnd"
      @wheel.prevent
    />

    <SearchOverlay
      :show="searchOverlayOpen"
      @enter="onSearchOverlayEnter"
      @after-leave="onSearchOverlayAfterLeave"
      @close-click="searchOverlayOpen = false"
      @song-click="song => onSearchOverlaySongClick(song)"
      @keyup:escape="searchOverlayOpen = false"
    />

    <AddToPlaylistModal
      :active-song-id="activeSongId"
      @view-playlist-click="onViewPlaylistClick">
    </AddToPlaylistModal>

    <Authentication
      @login:success="onLoginSuccess"
    ></Authentication>

    <b-modal id="modal-portrait-mobile-warning"
             role="modal"
             title="Mobile Version"
             title-tag="h4"
             header-class="justify-content-center text-uppercase"
             centered
             hide-header-close
             no-close-on-esc
             no-close-on-backdrop
             hide-footer>
      <p class="mobile-text">Close all other tabs</p>
      <p class="mobile-text">Rotate your device to landscape mode</p>
    </b-modal>

    <!-- Annotations -->
    <transition name="fade">
      <PoolAnnotation
        v-show="showHowToGuide"
        :number="1"
        @click.native="showHowToGuide = false"
      >
        <div class="pool-footer">
          <div class="pool-footer__unit">      
            <div class="pill-button pill-button--outline">
              Close How-To Guide
            </div>
          </div>
        </div>
      </PoolAnnotation>
    </transition>

    <transition
      name="fade"
      @enter="annotation2Seen = true"
      @leave="onAnnotation2Leave"
    >
      <PoolAnnotation
        v-if="showAnnotation2"
        :number="2"
        @click.native="showAnnotation2 = false"
      />
    </transition>

    <transition
      name="fade"
      @enter="annotation3Seen = true"
    >
      <PoolAnnotation
        v-if="showAnnotation3"
        :number="3"
        @click.native="showAnnotation3 = false"
      />
    </transition>    
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

// Components
import PoolHeader from '@/components/Dashboard/PoolHeader.vue'
import PoolCanvas from '@/components/Dashboard/PoolCanvas.vue'
import SplashOverlay from '@/components/Dashboard/SplashOverlay.vue'
import PoolAnnotation from '@/components/Dashboard/PoolAnnotation.vue'
import SearchOverlay from '@/components/Dashboard/SearchOverlay.vue'
import PoolPlaylists from '@/components/Dashboard/PoolPlaylists.vue'
import TransportDock from '@/components/Dashboard/TransportDock.vue'
import AddToPlaylistModal from '@/components/Dashboard/AddToPlaylistModal.vue'
import Authentication from '@/components/Dashboard/Authentication.vue'

const isFunction = obj => !!(obj && obj.constructor && obj.call && obj.apply)

const annotation2SeenCookieKey = 'annotation_2_seen'
const annotation3SeenCookieKey = 'annotation_3_seen'

export default {
  name: 'Pool',
  components: {
    PoolHeader,
    PoolCanvas,
    PoolAnnotation,
    PoolPlaylists,
    TransportDock,
    SearchOverlay,
    AddToPlaylistModal,
    Authentication,
    SplashOverlay
},
  data: function () {
    return {
      isLoadingSongs: true,
      playlistOpen: false,
      searchOverlayOpen: false,
      showTransportDock: false,
      transportDockTransitioning: false,
      showHowToGuide: false,
      showSplashOverlay: false,
      splashOverlayLoaded: false,
      splashOverlayLinks: null,
      showHowToGuidePrompt: false,
      showAnnotation2: false,
      showAnnotation3: false,
      annotation2Seen: false,
      annotation3Seen: false,
      authSuccessCallback: null,
      screenOrientation: false,
      songSelectedFlag: false // <- Flip to true as soon as any song is selected
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$screen.portrait && this.$screen.sm) {
        this.$bvModal.show('modal-portrait-mobile-warning')
      }
    })
  },
  beforeDestroy () {

  },
  computed: {
    ...mapState('wavpool', ['poolPlaylistsOpen', 'splashOverlayOpen']),
    ...mapGetters('auth', ['user', 'authenticated', 'isAdmin']),
    ...mapGetters('wavpool', [
      'activeSong',
      'activeSongId',
      'songs',
      'colors',
      'activePlaylistHash',
      'customPlaylistActiveKeywordIds'
    ]),
    transportDockVisible() {
      return this.showTransportDock && !this.showHowToGuide
    },
    transportDockIgnoreKeyEvents () {
      return this.searchOverlayOpen ||
             this.addToPlaylistModalVisible ||
             this.authenticationModalVisible
    },
    style () {
      if (!this.activeSongId) {
        return {}
      }

      return {
        '--selected-color': this.activeSong.hex_color
      }
    },
    showPoolCover () {
      let show = false

      if (this.showHowToGuide) {
        show = true
      }
      else if (this.showAnnotation2) {
        show = true
      }
      else if (this.customPlaylistActiveKeywordIds.length > 0) {
        show = false
      }
      else if (!this.songSelectedFlag && !this.activeSongId) {
        show = true
      }

      return show
    },
    showPoolCoverText() {
      return !this.showHowToGuide && !this.showAnnotation2 && !this.splashOverlayOpen
    }
  },
  methods: {
    ...mapMutations({
      'setPoolPlaylistsOpen': 'wavpool/SET_POOL_PLAYLISTS_OPEN',
      'setSplashOverlayOpen': 'wavpool/SET_SPLASH_OVERLAY_OPEN'
    }),
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('wavpool', [
      'LoadAllPlaylists',
      'LoadSongs',
      'LoadPlaylist',
      'LoadColors',
      'LoadKeywordGroups',
      'SelectSong',
      'SelectPlaylistSong',
      'DeselectSong',
      'SelectPlaylist',
      'DeselectPlaylist',
      'ResetHighlightedSongs',
      'TogglePoolPlaylistsOpen'
    ]),
    async loadData () {
      const isPlaylist = !!this.$route.params.playlist_hash

      // If we're not on the `/pools/:hash` route, load the splash overlay
      if (!isPlaylist) {
        this.showSplashOverlay = true
        this.loadSplashLinks()
      }

      await Promise.allSettled([
        this.LoadColors(), // Need to wait for this to finish so that we can use the colors in the PoolCanvas
        this.LoadAllPlaylists(),
        this.LoadSongs(),
        this.LoadKeywordGroups()
      ])

      // If we're on the `/pools/:hash` route, load the playlist associated with the hash
      if (isPlaylist) {
        await this.SelectPlaylist(this.$route.params.playlist_hash)
      }

      this.isLoadingSongs = false
    },
    loadSplashLinks () {
      this.$http.get('/splashlinks')
        .then(response => {
          this.splashOverlayLinks = response.data.splash_links
          if (this.validateSplashLinks()) {
            this.splashOverlayLoaded = true
          } else {
            this.showSplashOverlay = false
            this.setPoolPlaylistsOpen(true)
          }
        })
    },
    validateSplashLinks() {
      let valid = true
      Object.values(this.splashOverlayLinks).forEach(value => {
        valid = Object.values(value).every(v => {
          if (v !== null && v != '') return true
          return false
        })
      });
      return valid
    },
    checkAuth (authSuccessCallback = null) {
      // Set it to this instance var so we can use it in the loginSuccess method
      this.authSuccessCallback = authSuccessCallback

      // If they're already authenticated, call the callback immediately and then reset it
      if (this.authenticated) {
        isFunction(this.authSuccessCallback) && this.authSuccessCallback()
        this.authSuccessCallback = null
        return true
      }

      this.$bvModal.show('modal-authentication');

      return false
    },
    downloadSong () {
      if (!this.activeSongId) {
        return
      }

      // Create an invisible A element
      const a = document.createElement('a')
      a.style.display = 'none'

      document.body.appendChild(a)

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = `https://api.wav-pool.com/download/song/${this.activeSongId}`
      a.target = '_blank'

      // Use download attribute to set set desired file name
      a.setAttribute('download', 'testing.wav')

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    },
    updateUrl (url = '/') {
      history.replaceState({}, null, url)
    },
    onPlaylistClick (hash) {
      this.SelectPlaylist(hash)
    },
    onPlaylistSongClick (songId) {
      this.SelectPlaylistSong(songId)
      this.showTransportDock = true
    },
    onPoolSongSelected () {  
      this.showTransportDock = true
    },
    onPlaylistDeleted () {
      this.DeselectPlaylist()
    },
    onPlaylistsTabChangeNonPlaylist () {
      this.DeselectPlaylist()
    },
    onPlaylistsLeave() {
      this.ResetHighlightedSongs();
      this.updateUrl()
    },
    onSearchOverlaySongClick (song) {
      this.SelectSong({
        ...song,
        locate: true
      })
      this.showTransportDock = true
      this.searchOverlayOpen = false
    },
    onTransportDockCloseClick () {
      this.DeselectSong()
      this.showTransportDock = false
    },
    onTransportDockAddToPlaylistClick () {
      this.checkAuth(() => {
        this.$bvModal.show('modal-add-to-playlist')
      })
    },
    onTransportDockDownloadClick () {
      this.checkAuth(() => {
        this.downloadSong()
      })
    },
    onTransportDockTransitionStart() {
      this.transportDockTransitioning = true
    },
    onTransportDockTransitionEnd() {
      this.transportDockTransitioning = false
    },
    async onLoginSuccess () {
      await this.LoadAllPlaylists()

      if (isFunction(this.authSuccessCallback)) {
        this.authSuccessCallback()
        this.authSuccessCallback = null
      }
    },
    onViewPlaylistClick (playlist) {
      if (!playlist) {
        return
      }

      this.SelectPlaylist(playlist.hash)
    },
    onPoolHeaderGlobeClick() {
      if (this.authenticated) {
        this.logout()
      } else {
        this.$bvModal.show('modal-authentication')
      }
    },
    onSearchOverlayEnter() {
      this.setPoolPlaylistsOpen(false)
    },
    onSearchOverlayAfterLeave() {
      this.setPoolPlaylistsOpen(true)
    },
    onSplashOverlayAfterLeave() {
      this.setPoolPlaylistsOpen(true)
    },
    onSplashOverlayPlaylistClick(hash) {
      this.setSplashOverlayOpen(false)

      this.SelectPlaylist(hash)
    },
    onAnnotation2Leave() {
      if(!(this.annotation3Seen || this.$cookies.get(annotation3SeenCookieKey))) {
        this.showAnnotation3 = true
      }
    }
  },
  watch: {
    activeSongId(id) {
      if (id) {
        this.songSelectedFlag = true
      }

      if (id && !(this.annotation2Seen || this.$cookies.get(annotation2SeenCookieKey))) {
        this.showAnnotation2 = true
      }
    },
    annotation2Seen() {
      this.$cookies.set(annotation2SeenCookieKey, true)
    },
    annotation3Seen() {
      this.$cookies.set(annotation3SeenCookieKey, true)
    },
    searchOverlayOpen () {
      document && document.body.classList.toggle('search-overlay-open', this.searchOverlayOpen)
    },
    activePlaylistHash (hash) {
      if (hash) {
        this.setPoolPlaylistsOpen(true)
        this.updateUrl(`/pools/${hash}`)
      } else {
        this.updateUrl()
      }
    },
    '$screen.width'() {
      this.$nextTick(() => {
        if (this.$screen.portrait && this.$screen.sm) {
          this.$bvModal.show('modal-portrait-mobile-warning');
        }

        if (this.$screen.landscape && this.$screen.sm) {
          this.$bvModal.hide('modal-portrait-mobile-warning')
        }

        setTimeout(function () {
          let height = '100%';

          if (
            navigator.maxTouchPoints
            && navigator.userAgent.includes('Safari')
            && !navigator.userAgent.includes('Chrome')
          ) {
            height = '100vh';
          }

          document.getElementsByTagName('html')[0].style.height = height;
        }, 500);
      });
    }
  }
}
</script>

<style lang="scss">
html, body, #app, .pool-container {
  width: 100%;
  height: 100%;
}

body, main#app {
  height: var(--unit-100vh, 100vh);
}
</style>

<style lang="scss" scoped>
$desktop-screen-width: 900px;

.pool-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  background-color: var(--selected-color, white);
  transition: background 3.5s cubic-bezier(0.08, 0.3, 0.12, 1);

  & * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

.pool-gradient-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 60%, white)
}

.pool-canvas-mask {
  width: 100%;
  height: 100%;

  --mask-radius: 55vw;

  clip-path: circle(var(--mask-radius) at center);

  @media (min-width: $desktop-screen-width) {
    --mask-radius: 37vw;
  }
}

.pool-cover {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  background-color: #111;
  color: white;
}

.pool-footer {
  pointer-events: none; /* @TODO - Place things better so we don't need to do this */
  position: fixed;
  z-index: 10;
  bottom: 15px;
  left: 0;
  left: calc(env(safe-area-inset-left, 0) / 2);
  right: 0;
  right: calc(env(safe-area-inset-right, 0) / 2);
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pool-footer__unit {
  pointer-events: auto;
}

.text-uppercase {
  text-transform: uppercase;
}

.mobile-text {
  font-size: 1.2rem;
  text-align: center;
}
</style>
