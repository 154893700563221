<template>
  <b-modal id="modal-add-to-playlist" ref="modal" size="lg" title="Add To Playlist" hide-footer centered
           @show="resetModal"
           @hidden="resetModal">
    <!-- Add to Playlist -->
    <div
      v-if="hasEditablePlaylists"
      class="mb-5"
    >
      <div class="form-group">
        <label for="select_existing_playlist">Select A Playlist</label>
        <v-select
          v-model="selectedPlaylist"
          :options="editablePlaylists"
          id="select_existing_playlist"
          label="title"
        />
      </div>

      <b-button
        variant="primary"
        :disabled="selectedPlaylist === null"
        @click="addToPlaylist"
      >Add</b-button>
    </div>

    <!-- Create new Playlist -->
    <div>
      <h5>Create New Playlist</h5>

      <div class="form-group">
        <label for="new_playlist_name">Name</label>
        <input
          class="form-control"
          type="text"
          id="new_playlist_name"
          v-model="title"
        />
      </div>

      <!--

      Can't create public playlists here due to data issues
      - The '/playlist/create' endpoint returns an object with a 'playlists' property but that only contains the private playlists for that user
      - The endpoint return object also includes a 'playlist' property but it is incomplete
      - The only way to access the newly created playlist is to pull it from the 'playlists' property array
      - But that playlist doesn't exist if we try to create a public one

      - see note below in methods->createPlaylist

      <div v-if="userIsAdmin">
          <div class="form-group">
              <label>Status</label>

              <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="new_playlist_status" id="new_playlist_status_private" value="private" v-model="status">
                  <label class="custom-control-label" for="new_playlist_status_private">
                      Private
                  </label>
              </div>
              <div class="custom-control custom-radio">
                  <input class="custom-control-input" type="radio" name="new_playlist_status" id="new_playlist_status_public" value="public" v-model="status">
                  <label class="custom-control-label" for="new_playlist_status_public">
                      Public
                  </label>
              </div>
          </div>
      </div>
       -->

      <b-button variant="primary" :disabled="titleIsBlank" @click="createPlaylist">Create</b-button>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'AddToPlaylistModal',
  props: {
    activeSongId: [String, Number],
  },
  data: () => ({
    title: '',
    description: null,
    status: 'private',
    selectedPlaylist: null,
  }),
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('wavpool', [
      'editablePlaylists'
    ]),
    titleIsBlank() {
      return (this.title.length === 0 || !this.title.trim());
    },
    hasEditablePlaylists() {
      return this.editablePlaylists && this.editablePlaylists.length;
    }
  },
  methods: {
    ...mapActions('wavpool', [
      'AddPlaylist',
      'ReloadPlaylist'
    ]),
    createPlaylist() {
      this.$http.post('/playlist/create', {
        playlist: {
          title: this.title,
          description: this.description,
          status: this.status,
        },
        song: this.activeSongId
      }).then(({data}) => {
        if (data.msg === 'ok') {
          // This endpoint returns a data object like
          // { msg, new_playlist, playlists }
          // and for some reason 'new_playlist' is formatted differently than the items in the playlist array (it's missing 'status' among other things)
          // Instead of using it directly, we have to find the newly created playlist inside the array of all playlists for the user
          const newPlaylist = data.playlists.find(({id}) => id == data.new_playlist.id)

          this.AddPlaylist(newPlaylist)
            .then(() => {
              this.$vs.notification({
                position: 'top-center',
                title: 'Song Added!',
                text: 'Click to view playlist.',
                duration: 5000,
                onClick: () => {
                  this.$emit('view-playlist-click', newPlaylist)
                }
              })
            });

          this.$nextTick(() => {
            this.$bvModal.hide('modal-add-to-playlist');
          });
        }
      }).catch(err => {
        console.log(err)

        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops!',
          text: 'Something went wrong, check the console'
        })
      })
    },
    addToPlaylist() {
      if (this.selectedPlaylist === null) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops!',
          text: 'You must select a playlist before adding'
        })

        return
      }

      this.$http
        .post(`/playlist/${this.selectedPlaylist.id}/add`, {
          song: this.activeSongId
        })
        .then(({data}) => {
          if (data.msg === 'ok') {

            this.$vs.notification({
              position: 'top-center',
              title: 'Song Added!',
              text: 'Click to view playlist.',
              duration: 5000,
              onClick: () => {
                this.$emit('view-playlist-click', data.playlist);
              }
            })

            this.ReloadPlaylist(data.playlist.hash);

            this.$nextTick(() => {
              this.$bvModal.hide('modal-add-to-playlist');
            });
          }
        })
        .catch(err => {
          console.log(err)
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops!',
            text: 'Something went wrong, check the console'
          })
        })
    },
    resetModal() {
      this.title = '';
      this.description = null;
      this.status = 'private';
      this.selectedPlaylist = null;
    }
  }
}
</script>
