<template>
  <div class="keyword-list">
    <div class="pill-button-group">
      <button
        v-for="(keyword, i) in keywords"
        :key="`keyword-${i}`"
        :class="[
          'pill-button pill-button--sm pill-button--selectable',
          { 'is-selected': activeKeywordIds.includes(keyword.id) }
        ]"
        @click="$emit('keyword-click', keyword)"
      >
        {{ keyword.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keywords: {
      type: Array,
      default: () => []
    },
    activeKeywords: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeKeywordIds() {
      return this.activeKeywords.filter(Boolean).map(k => k.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.keyword-list {
  .pill-button-group {
    flex-wrap: wrap;
    gap: 8px 5px;
  }

  .pill-button {
    flex-grow: 1;
  }

  @media (hover: hover) {
    .pill-button.pill-button--selectable:not(.is-selected):hover {
      border-color: white;
    }
  }
}
</style>