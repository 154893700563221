<template>
    <transition
        name="transport-dock"
        v-on:before-enter="onBeforeEnter"
        v-on:after-enter="onAfterEnter"
        v-on:before-leave="onBeforeLeave"
        v-on:leave="onLeave"
        v-on:after-leave="onAfterLeave"
    >
        <div
            class="transport-dock"
            v-show="show"
        >
            <div class="transport-dock__dialogs">
                <div
                    v-if="displayEditableSong && isAdmin"
                    class="transport-dock__dialog transport-dock__dialog--mini"
                >
                    <div
                        class="transport-dock__contents"
                    >
                        <div class="segment segment--meta">
                            <div class="meta-header">
                                <h6>Metadata</h6>
                                <a
                                    v-if="editingMeta"
                                    href="#"
                                    @click.prevent="editingMeta = false"
                                    v-text="'Cancel'"
                                    class="pill-button pill-button--outline pill-button--sm"
                                />

                                <a
                                    v-else
                                    href="#"
                                    @click.prevent="editingMeta = true"
                                    title="Edit Metadata"
                                    v-text="'Edit'"
                                    class="pill-button pill-button--outline pill-button--sm"
                                />
                            </div>
                            <div class="meta-row">
                                <div class="meta">
                                    <div class="meta-label">X Value</div>

                                    <div
                                        v-if="editingMeta"
                                        class="meta-value"
                                    >
                                        <input
                                            type="text"
                                            v-model="displayEditableSong.x"
                                            class="form-control form-control-sm"
                                            style="width: 60px"
                                        />
                                    </div>
                                    <div
                                        v-else
                                        class="meta-value"
                                    >
                                        <span>{{ displayEditableSong.x }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="editingMeta"
                                class="meta-row"
                            >
                                <button
                                    class="pill-button pill-button--outline"
                                    v-on:click="saveMetaData"
                                    :disabled="savingMeta"
                                    v-text="savingMeta ? 'Saving...' : 'Save'"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="transport-dock__close">
                    <button class="pill-button" @click="onCloseClick">
                      Close
                    </button>
                </div>

                <div class="transport-dock__dialog">
                    <div
                        v-if="activeSongId"
                        class="transport-dock__contents"
                    >
                        <div class="segment segment--info">
                            <h4
                                style="font-weight: normal; margin-bottom: 10px;"
                                v-html="activeSong.title"
                            />
                            <div class="meta-row">
                                <div class="meta">
                                    <div class="meta-label">Artist</div>
                                    <div class="meta-value">{{ activeSong.artist }}</div>
                                </div>
                                <div class="meta">
                                    <div class="meta-label">BPM</div>
                                    <div class="meta-value">{{ activeSong.display_bpm ? activeSong.display_bpm : 'N/A' }}</div>
                                </div>
                                <div class="meta">
                                    <div class="meta-label">Rights</div>
                                    <div class="meta-value" :style="{
                                        'text-decoration': activeSong.display_one_stop ? 'line-through' : 'none'
                                    }">One Stop</div>
                                </div>
                            </div>
                        </div>

                        <div class="segment segment--wave">
                            <WavForm
                                ref="wavform"
                                :song="activeSong"
                                :noplaymiddle="true"
                                :autoplay="autoplay"
                                :ignoreKeyEvents="ignoreKeyEvents"
                            />
                        </div>

                        <div class="segment segment--actions">
                            <div class="pill-button-group">
                                <a
                                    v-if="isAdmin"
                                    class="pill-button pill-button--black"
                                    :href="`/admin/songs/${activeSongId}/`"
                                    target="_blank"
                                    v-text="'View in Admin'"
                                />
                                <a
                                    v-else
                                    class="pill-button pill-button--black"
                                    :href="`mailto:info@wav-pool.com?subject=Licensing:%20${activeSong.title}%20by%20${activeSong.artist}`"
                                    target="_blank"
                                    v-text="'License This Song'"
                                />
                            </div>
                            <div class="pill-button-group">
                                <button class="pill-button pill-button--outline" @click="onAddToPlaylistClick">
                                    Add To Playlist
                                </button>
                                <button class="pill-button pill-button--outline" @click="onDownloadClick">
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import WavForm from '@/components/Songs/WavForm'

export default {
  name: 'TransportDock',
  components: {
    WavForm
  },
  props: {
    show: {
      type: Boolean
    },
    ignoreKeyEvents: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      displayEditableSong: null,
      editingMeta: false,
      savingMeta: false
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('wavpool', [
      'userPlaylists',
      'publicPlaylists',
      'activeSong',
      'activeSongId',
      'publicPlaylists',
      'userPlaylists'
    ]),
    autoplay () {
      return this.show
    },
    allPlaylists () {
      let playlists = this.userPlaylists

      if (this.isAdmin) {
        playlists = playlists.concat(this.publicPlaylists)
      }

      return playlists
    },
  },
  watch: {
    activeSong(newSong) {
      if (newSong) {
        if (this.isAdmin) {
          this.$http.get(`/admin/songs/${this.activeSongId}`)
            .then(response => response.data.song)
            .catch(console.log)
            .then((editableSong) => this.displayEditableSong = editableSong);
        } else {
          this.displayEditableSong = null;
        }
      }
    },
    displayEditableSong () {
      // Reset editing variables when switching display editable song
      this.savingMeta = false
    }
  },
  methods: {
    ...mapActions('wavpool', [
      'LoadSongs',
    ]),
    pause () {

    },
    onCloseClick () {
      this.$emit('click-close')
    },
    onDownloadClick () {
      this.$emit('click-download')
    },
    onAddToPlaylistClick () {
      this.$emit('click-add-to-playlist')
    },
    onBeforeEnter() {
        this.$emit('transition-start')
    },
    onAfterEnter() {
        this.$emit('transition-end')
    },
    onBeforeLeave() {
        this.$emit('transition-start')
    },
    onLeave () {
      this.$refs.wavform && this.$refs.wavform.pause()
    },
    onAfterLeave() {
        this.$emit('transition-end')
    },
    saveMetaData () {
      if (this.savingMeta) {
        return;
      }

      this.savingMeta = true

      this.$http.post('/admin/songs/update', {
        song: this.displayEditableSong
      })
        .then(({ data }) => {
          if (data.status && data.status === 'OK') {
            this.LoadSongs();

            this.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Song Updated'
            })
          }

          this.savingMeta = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
$shadow: 0 4px 10px 0 transparentize(black, 0.95),
         0 4px 20px 10px transparentize(black, 0.9);

.transport-dock {
  position:fixed;
  z-index:2;
  left: 0;
  left: env(safe-area-inset-left, 0);
  right: 0;
  right: env(safe-area-inset-right, 0);
  padding-left: 15px;
  padding-right: 15px;
  bottom: 55px;
  pointer-events: none; /* Put them back on the individual dialog elements */
}

.transport-dock__dialogs {
    margin: 0 auto;
    max-width: 1300px;
}

.transport-dock__dialog {
    position: relative;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: $shadow;
    pointer-events: auto;

    @media (min-width: 900px) {
        padding: 0 30px;
    }

    & + & {
        margin-top: 8px;
    }

    &--mini {
      display: inline-block;
      padding-right: 20px;
      top: 25px;
    }
}

.transport-dock__contents {
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
        flex-direction: row;
    }
}

.segment {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & + .segment {
        margin-top: 15px;
    }

    @media (min-width: 900px) {
        padding: 15px;

        & + .segment {
            margin-top: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.segment--info {
            flex: 0.8;
            flex: 0 1 auto;
        }

        &.segment--wave {
            flex: 1;
        }

        &.segment--meta {
            padding: 20px 0;
        }
    }

    &.segment--actions {
        .pill-button-group {
            justify-content: flex-end;
        }
    }
}

.meta-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    h6 {
        margin-bottom: 0;
        margin-right: 20px;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 0.8rem;
    }

    svg {
        height: 14px;
        width: 14px;
        position: relative;
        top: -2px;
    }
}

.meta-row {
    display: flex;

    & + & {
        margin-top: 15px;
    }
}

.meta {
    & + & {
        margin-left: 44px;
    }
}

.meta-label {
    color: #939393;
    text-transform: uppercase;
    margin-bottom: 1px;
    margin-left: 1px;
    font-size: 11px;
}

.meta-value {
    .form-control-sm {
        border-width: 1px;
    }
}

.transport-dock__close {
    position: relative;
    z-index: 1; // Above the __dialog shadow
    margin-bottom: 8px;
    text-align: right;

    button {
        pointer-events: auto;
        box-shadow: $shadow;
    }
}
</style>
