<template>
  <b-modal id="modal-authentication" ref="modal" :title="type === 'login' ? 'Login' : 'Create an Account'" hide-footer centered>
    <form @submit.prevent="onFormSubmit">
      <div
        v-if="type === 'login'"
      >
        <p
          v-if="loginFailedMessage"
          v-text="loginFailedMessage"
          class="text-danger"
        />

        <div class="form-group">
          <label for="email">Email address</label>
          <input :class="[{'is-invalid': isInvalidEmail},'form-control']" v-model="email" type="email"
                 id="email" aria-describedby="emailHelp">
          <div v-if="isInvalidEmail" class="invalid-feedback">{{ validation_errors.email }}</div>
          <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input :class="[{'is-invalid': isInvalidPassword},'form-control']" v-model="password"
                 type="password" id="password">
          <div v-if="isInvalidPassword" class="invalid-feedback">{{ validation_errors.password }}</div>
        </div>
        <div class="form-group">
          <b-button
            variant="primary"
            block
            type="submit"
            v-text="loading ? 'Logging in...' : 'Login'"
            :disabled="loading"
          />
        </div>

        <a href="#" @click.prevent="type = 'registration'">
          Don't have an account? <span style="text-decoration: underline">Create an account here</span>
        </a>
      </div>
      <div v-if="type === 'registration'">
        <div class="form-group">
          <label for="email">Email address</label>
          <input :class="[{'is-invalid': isInvalidEmail},'form-control']" v-model="email" type="email" id="email">
          <div v-if="isInvalidEmail" class="invalid-feedback">{{ validation_errors.email }}</div>
        </div>
        <div class="form-group">
          <label for="name">Full Name</label>
          <input :class="[{'is-invalid': isInvalidName},'form-control']" v-model="name" type="text" id="name">
          <div v-if="isInvalidName" class="invalid-feedback">{{ validation_errors.name }}</div>
        </div>
        <div class="form-group">
          <label for="email">Website</label>
          <input :class="[{'is-invalid': isInvalidWebsite},'form-control']" v-model="website" type="website"
                 id="website">
          <div v-if="isInvalidWebsite" class="invalid-feedback">{{ validation_errors.website }}</div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input :class="[{'is-invalid': isInvalidPassword},'form-control']" v-model="password" type="password"
                 id="password">
          <div v-if="isInvalidPassword" class="invalid-feedback">{{ validation_errors.password }}</div>
        </div>
        <div class="form-group">
          <label for="password_confirmation">Confirm Password</label>
          <input :class="[{'is-invalid': isInvalidPasswordConfirmation},'form-control']" v-model="password_confirmation"
                 type="password" id="password_confirmation">
          <div v-if="isInvalidPasswordConfirmation" class="invalid-feedback">
            {{ validation_errors.password_confirmation }}
          </div>
        </div>

        <div class="form-group">
          <b-button
            variant="primary"
            block
            type="submit"
            v-text="loading ? 'Registering...' : 'Register'"
            :disabled="loading"
          />
        </div>

        <a
          href="#"
          @click.prevent="type = 'login'"
        >
          Already have an account? <span style="text-decoration: underline">Login here</span>
        </a>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Authentication',
  data: () => ({
    type: 'login',
    email: '',
    name: '',
    website: '',
    password: '',
    password_confirmation: '',
    validation_errors: {
      email: '',
      name: '',
      password: '',
      password_confirmation: '',
      website: ''
    },
    loginFailedMessage: false,
    loading: false
  }),
  computed: {
    ...mapGetters('auth', ['user']),
    isInvalidEmail: function () {
      return this.validation_errors.email !== ''
    },
    isInvalidName: function () {
      return this.validation_errors.name !== ''
    },
    isInvalidWebsite: function () {
      return this.validation_errors.website !== ''
    },
    isInvalidPassword: function () {
      return this.validation_errors.password !== ''
    },
    isInvalidPasswordConfirmation: function () {
      return this.validation_errors.password_confirmation !== ''
    },
  },
  methods: {
    ...mapActions('auth', ['setSession']),
    handleReset () {
      this.type = 'login'
      this.email = ''
      this.password = ''
      this.password_confirmation = ''
      this.name = ''
      this.website = ''
      this.validation_errors = {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        website: ''
      }
      this.loginFailedMessage = false
    },
    validateRegistration () {
      this.loading = true

      this.$http.post('/register', {
        email: this.email,
        name: this.name,
        website: this.website,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then(({ data }) => {
          const {
            message,
            user,
            validation_errors
          } = data

          if (message === 'success') {
            // user is now logged in, redirect to dashboard route '/'
            this.setSession({
              isAuthenticated: true,
              user
            })
              .then(() => {
                this.$emit('login:success')
              })
              .finally(() => {
                this.handleReset();
                this.$bvModal.hide('modal-authentication')
              })

            return
          }

          Object.entries(validation_errors).forEach((el, i) => {
            this.validation_errors[el[0]] = el[1].join(' ')
          })

          this.loading = false
        })
    },
    validateLogin () {
      this.loading = true

      this.$http.post('/login', {
        email: this.email,
        password: this.password
      })
        .then(({ data }) => {
          const {
            message,
            user,
            validation_errors
          } = data

          if (message === 'success') {
            this.setSession({
              isAuthenticated: true,
              user
            })
              .then(() => {
                this.$emit('login:success')
              })
            .finally(() => {
              this.handleReset();
              this.$bvModal.hide('modal-authentication')
            });
          } else {
            this.$emit('login:fail')
            this.loginFailedMessage = message

            if (validation_errors != null) {
              Object.entries(validation_errors).forEach((el, i) => {
                this.validation_errors[el[0]] = el[1].join(' ')
              })
            }
          }

          this.loading = false
        })
    },
    onFormSubmit () {
      if (this.type === 'login') {
        this.validateLogin()
      } else if (this.type === 'registration') {
        this.validateRegistration()
      } else {
        console.warn('No type action found')
      }
    }
  }
}
</script>
