<template>
	<div class="pool-header">
        <div class="pool-header__unit pool-header__unit--brand">
            <a
                target="_blank"
                href="https://www.wav-pool.com"
                title="More Info"
            >
                <span class="pill">.Wav Pool is a music licensing</span>
                <br />
                <span class="pill">agency and exploration</span>
                <br />
                <span class="pill">platform</span>
            </a>
        </div>

        <div class="pool-header__unit pool-header__unit--controls">
            <a
                href="#"
                :class="[
                    'globe',
                    { 'show-label': showGlobeLabel }
                ]"
                :title="globeLabelTitle"
                @click.prevent="onClick"
                @mouseenter="onMouseenter"
                @mouseleave="onMouseleave"
            >
                <span class="globe__label">
                    {{ globeLabelText }}
                </span>

                <img src="/img/icon-green-globe.png" :class="[{ 'on': authenticated }]" />
            </a>
        </div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'PoolHeader',
  data: () => ({
    showGlobeLabel: false
  }),
  computed: {
    ...mapGetters('auth', ['authenticated']),
    globeLabelText() {
        return this.authenticated ? 'Logout' : 'Login'
    },
    globeLabelTitle() {
        return this.authenticated ? '' : 'Login to gain full access to create & share custom playlists and save songs for later access.'
    }
  },
  methods: {
    onClick() {
        this.$emit('globe-click')
        this.showGlobeLabel = false
    },
    onMouseenter() {
        this.$nextTick(() => {
            this.showGlobeLabel = true
        })
    },
    onMouseleave() {
        this.showGlobeLabel = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pool-header {
    pointer-events: none; /* @TODO - Place things better so we don't need to do this */
    position: fixed;
    z-index: 105;
    top: 15px;
    left: 0;
    left: calc(env(safe-area-inset-left, 0) / 2);
    right: 0;
    right: calc(env(safe-area-inset-right, 0) / 2);
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.pool-header__unit {
    pointer-events: auto;

    &.pool-header__unit--brand {
        line-height: 1.1;
    }
}

.pill-button + .pill-button {
    margin-left: 5px;
}

.pool-header__unit--controls .pill-button {
    min-width: 64px;
    text-align: center;
}

@keyframes spin {
    from { transform: rotateY(0); }
    to { transform: rotateY(360deg); }
}

.globe {
    display: block;
    position: relative;

    .globe__label {
        position: absolute;
        top: 42%;
        left: 0;
        transform: translate(-95%, -50%);
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 12px;
        padding-right: 8px;

        pointer-events: none;
        opacity: 0;

        transition: transform 300ms ease,
                    opacity 300ms ease-out;
    }

    &.show-label .globe__label {
        opacity: 1;
        transform: translate(-100%, -50%);
        transition: transform 300ms cubic-bezier(0.36, 0.28, 0.05, 0.94),
                    opacity 300ms ease-in;        
    }
    
    img {
        display: block;
        position: relative;
        top: -6px;
        right: -2px;
        height: 50px;
        filter: brightness(0);        
        transition: filter 400ms ease;

        &.on {
            filter: brightness(1);
            animation: spin 5s linear infinite;
        }        
    }
}
</style>
