<template>
    <transition
        name="search-overlay"
        v-on:enter="onEnter"
        v-on:after-enter="onAfterEnter"
        v-on:enter-cancelled="onEnterCancelled"
        v-on:leave="onLeave"
        v-on:after-leave="onAfterLeave"
        v-on:leave-cancelled="onLeaveCancelled"
    >
        <div
            v-show="show"
            class="pool-search"
        >
            <transition name="search-overlay-bottom">
                <div class="pool-search__bottom" v-show="showSearchBottom">
                    <div class="pool-search__bottom-contents">
                        <div class="pool-search__input">
                            <div class="pool-search__input-title">
                                Type To Search by Title or Artist&hellip;
                            </div>
                            <div class="pool-search__input-widget" @touchstart="focusInput">
                                <svg
                                    @click="onSearchIconClick"
                                    title="Click to search"
                                    class="clickable"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px" y="0px" viewBox="0 0 100 125" enable-background="new 0 0 100 100" xml:space="preserve">
                                    <g>
                                        <rect x="54" y="18" width="9" height="9"/>
                                        <polygon points="63,45 63,54 72,54 72,45 72,36 72,27 63,27 63,36  "/>
                                        <rect x="54" y="54" width="9" height="9"/>
                                        <rect x="63" y="63" width="9" height="9"/>
                                        <rect x="72" y="72" width="9" height="9"/>
                                        <rect x="81" y="81" width="9" height="9"/>
                                        <polygon points="18,36 18,27 9,27 9,36 9,45 9,54 18,54 18,45  "/>
                                        <rect x="18" y="54" width="9" height="9"/>
                                        <polygon points="45,18 54,18 54,9 45,9 36,9 27,9 27,18 36,18  "/>
                                        <polygon points="36,63 27,63 27,72 36,72 45,72 54,72 54,63 45,63  "/>
                                        <rect x="18" y="18" width="9" height="9"/>
                                    </g>
                                </svg>

                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    aria-label="Search Songs"
                                    v-model="searchTerm"
                                    ref="input"
                                    @keyup="onKeyup"
                                />
                            </div>
                        </div>

                        <a
                            href="#"
                            class="pool-search__close pill-button pill-button--outline"
                            @click.prevent="$emit('close-click')"
                        >
                            CLOSE SEARCH
                        </a>
                    </div>
                </div>
            </transition>

            <transition name="search-overlay-results">
                <div
                    class="search-results"
                    v-show="showSearchResults"
                    ref="results"
                >
                    <div class="search-results__display">
                        <div v-if="loading">
                            Searching...
                        </div>
                        <div
                            v-else-if="songs.length"
                            class="search-results__list-container"
                        >
                            <p
                                v-if="searchListTitle"
                                v-text="searchListTitle"
                            />

                            <ul class="list-group">
                                <li
                                    v-for="(song, i) in songs"
                                    :key="i"
                                    class="search-results__result"
                                    @click="onSongClick(song)"
                                >
                                    <span class="search-results__result-chunk">{{ padIndex(i+1) }}.</span>
                                    <span class="search-results__result-chunk" v-text="song.title" />
                                    <span class="search-results__result-chunk" v-text="song.artist" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </transition>

            <div
                class="pool-search__backdrop"
                @click="$emit('close-click')"
                title="Click to close"
            />
        </div>
    </transition>
</template>

<script>
const MIN_SEARCH_LENGTH = 2
const AUTOSEARCH_THROTTLE_MS = 300

export default {
  name: 'PoolSearch',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    searchTerm: '',
    songs: [],
    showSearchBottom: false,
    showSearchResults: false
  }),
  created () {
    this.timeout = null
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  computed: {
    searchListTitle () {
      let t = ''
      const l = this.songs.length

      if (l) {
        if (l === 1) {
          t = '1 song found'
        } else {
          t = `${l} songs found`

          if (l > 500) {
            t = '500+ songs Found'
          }
        }
      }

      return t
    },
    showResults () {
      return this.songs.length
    }
  },
  methods: {
    padIndex (i) {
      return i < 10 ? `0${i}` : i
    },
    clear () {
      this.loading = false
      this.searchTerm = ''
      this.songs = []
    },
    async search () {
      if (!this.searchTerm || this.searchTerm.length === 0) {
        return;
      }

      this.loading = true

      this.$http.post('/songs/search', {search_string: this.searchTerm})
        .then(response => response.data.songs)
        .catch(console.log)
        .then(songs => this.songs = songs);

      this.loading = false
    },
    focusInput(e) {
      e && e.preventDefault()
      this.$refs.input.focus()
    },
    onSongClick (song) {
      this.$emit('song-click', song)
    },
    onEnter() {
      this.$emit('enter')
    },
    onEnterCancelled () {
      this.showSearchBottom = false
      this.showSearchResults = false
    },
    onAfterEnter () {
      this.showSearchBottom = true
      this.showSearchResults = true

      this.$nextTick(this.focusInput)
    },
    onLeave () {
      this.showSearchBottom = false
      this.showSearchResults = false
    },
    onAfterLeave() {
      this.$emit('after-leave')
    },
    onLeaveCancelled () {
      this.showSearchBottom = true
      this.showSearchResults = true
    },
    onKeyup (e) {
      const KEYBOARD_ESCAPE = 27;

      if (e.which === KEYBOARD_ESCAPE) {
        this.$emit('keyup:escape')
      }
    },
    onSearchIconClick () {
      this.search()
    }
  },
  watch: {
    loading () {
      if (this.$refs.results) {
        this.$refs.results.scrollTop = 0
      }
    },
    searchTerm () {
      clearTimeout(this.timeout)

      if (!this.searchTerm || this.searchTerm.trim() == '') {
        this.clear()
        return
      }

      if (this.searchTerm.length < MIN_SEARCH_LENGTH) {
        return;
      }

      this.timeout = setTimeout(this.search.bind(this), AUTOSEARCH_THROTTLE_MS)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-overlay-enter,
.search-overlay-leave-to {
    opacity: 0;
}

.search-overlay-enter-active,
.search-overlay-leave-active {
  transition: opacity .35s cubic-bezier(0.38, 0.18, 0.9, 0.63);
}

.search-overlay-bottom-enter,
.search-overlay-bottom-leave-to {
    opacity: 0;
}

.search-overlay-bottom-enter-active,
.search-overlay-bottom-leave-active {
  transition: opacity .35s cubic-bezier(0.29, 0.38, 0.93, 0.51);
}

.search-overlay-results-enter {
    opacity: 0;
    transform: translateY(10px);
}

.search-overlay-results-leave-to {
    opacity: 0;
}

.search-overlay-results-enter-active,
.search-overlay-results-leave-active {
    transition: opacity .35s cubic-bezier(0.29, 0.38, 0.93, 0.51),
                transform .35s cubic-bezier(0.25, 0.1, 0, 0.88);
}

.search-overlay-results-enter-active {
    transition-delay: 0.25s;
}

.pool-search {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize(white, 0.3);
}

.pool-search__bottom {
    position: fixed;
    left: 0;
    left: env(safe-area-inset-left, 0);
    right: 0;
    right: env(safe-area-inset-right, 0);
    bottom: 0;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50vh;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0));
        pointer-events: none;
    }
}

.pool-search__bottom-contents {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 25px 10px;  
}

.pool-search__input {
    flex: 1;
}

.pool-search__input-title {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 14px;
}

.pool-search__input-widget {
    position: relative;

    svg {
        position: absolute;
        top: 10px;
        left: -4px;
        width: 65px;
        height: auto;
    }

    input {
        padding: 0;
        padding-left: 75px; // Make room for the search icon
        font-size: 80px;
        color: black;
        height: 82px;
        line-height: 1;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        transition: all 550ms cubic-bezier(0.21, 0.65, 0.2, 1);

        &::placeholder {
            opacity: 0.25;
        }

        &:focus {
            color: black; /* @TODO - Remove this once we drop bootstrap form styles */
            box-shadow: none; /* @TODO - Remove at some point, this is just needed to override default bootstrap styles */
        }
    }
}

.search-results {
    position: fixed;
    top: 0;
    left: 0;
    left: env(safe-area-inset-left, 0);
    right: 0;
    right: env(safe-area-inset-right, 0);
    bottom: 0;
    z-index: -1;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.search-results__display {
    margin: 40vh 0 50vh;
    padding: 0 25px;
}

.search-results__list-container {
    width: 100%;
    max-width: 800px;
    text-transform: uppercase;

    @media (min-width: 900px) {
        width: 50vw;
    }

    .list-group {
        overflow: auto;
        max-height: none;
        list-style: none;
        padding: 0;  
    }    
}

li.search-results__result {
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  &:hover {
      color: #76ff41;
  }

  & + & {
    margin-top: 3px;
  }
}

.search-results__result-chunk {
  flex: 1;

  &:first-child {
    width: 38px;
    flex: 0 1 auto;
  }

  &:last-child {
    text-align: right;
  }
}

.pool-search__close {
    position: relative;
    // Since __bottom-contents padding doesn't match .pool-footer padding, fix position with these values
    right: -10px;
    bottom: 5px;
}

.pool-search__backdrop {
    position: fixed;
    z-index: -2; /* Put it behind the search results */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
</style>
