<template>
  <div
    v-if="playlist"
    class="d-flex align-items-end"
  >
    <div
      v-if="playlist.thumbnail"
      class="mr-3"
      style="width: 160px; flex-shrink: 0;"
    >
      <img
        :src="playlist.thumbnail"
        width="100%"
        class="hero-image img-fluid"
        :style="{
          transition: 'opacity 300ms ease',
          opacity: thumbLoaded ? 1 : 0
        }"
        @load="thumbLoaded = true"
      >
    </div>

    <div>
      <h2 class="mb-0 font-weight-normal text-uppercase" style="line-height: 1;" v-text="playlist.title"/>
      <div class="mt-2 px-1 small text-uppercase" style="line-height: 1">
        <span
          v-text="playlist.songs ? (playlist.songs.length === 1 ? '1 track' : `${playlist.songs.length} tracks`) : 'No tracks'"
        />
        <span v-if="playlist.status === 'private'">
          &nbsp;&middot;&nbsp; Saved Playlist
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playlist: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    thumbLoaded: false
  })
}
</script>

<style lang="scss" scoped>
.hero-image {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 10px -6px #00000082;
}

h2 {
  font-size: 1.85rem;
}
</style>