<template>
  <div
    :class="[
      'poolbar-header',
      { 'is-overlapping': overlap }
    ]">
    <div class="poolbar-header__row">
      <div class="pill-button-group">
        <!-- Curated -->
        <a href="#" :class="buttonClass(tab === 'public', isPlaylistTabDetail('public'))" @click.prevent="onClick('public')">
          <transition name="reveal-all">
            <span
              v-if="isPlaylistTabDetail('public')"
              class="reveal-all"
            >← All &nbsp;&nbsp;</span>
          </transition>Curated
        </a>

        <!-- Artists -->
        <a href="#" 
          :class="buttonClass(tab === 'artists', isPlaylistTabDetail('artist'))" 
          @click.prevent="onClick('artists')"
        >
          <transition name="reveal-all">
            <span
              v-if="isPlaylistTabDetail('artist')"
              class="reveal-all"
            >← All &nbsp;&nbsp;</span>
          </transition>Artists
        </a>

        <!-- Keyword -->
        <a href="#" :class="buttonClass(tab === 'custom')" @click.prevent="onClick('custom')">
          Keyword
        </a>        

        <!-- Saved -->
        <a
          v-if="userPlaylists.length && authenticated"
          href="#"
          :class="buttonClass(tab === 'private', isPlaylistTabDetail('private'))"
          @click.prevent="onClick('private')"
        >
          <transition name="reveal-all">
            <span
              v-if="isPlaylistTabDetail('private')"
              class="reveal-all"
            >← All &nbsp;&nbsp;</span>
          </transition>Saved
        </a>
      </div>
    </div>

    <transition
      name="fade-fast"
      mode="out-in"
    >
      <div
        :class="[
          'poolbar-header__row',
          { 'is-empty': titleText === null }
        ]"
        :key="titleText"
      >
        <h4>{{ titleText }}</h4>
      </div>
    </transition>   

    <transition name="prompt-popin">
      <div
        v-if="showScrollPrompt"
        class="scroll-prompt"
      >
        <span
          class="pill-button pill-button--sm pill-button--green"
          @click="$emit('scroll-prompt-click')"
        >↑ Back to top ↑</span>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    tab: {
      type: String,
      default: null
    },
    overlap: {
      type: Boolean,
      default: false
    },
    showScrollPrompt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'authenticated'
    ]),
    ...mapGetters('wavpool', [
      'activePlaylist',
      'userPlaylists'
    ]),
    titleText() {
      switch(this.tab) {
        case 'public':
          return 'Curated Playlists'
        case 'artists':
          return 'Featured Artists'
        case 'private':
          return 'Saved Playlists'
        case 'custom':
          return 'Keyword Playlist'
        case 'playlist':
          return null
        default:
          return null
      }
    }    
  },
  methods: {
    isPlaylistTabDetail(status = 'public') {
      return this.tab === 'playlist' && this.activePlaylist && this.activePlaylist.status === status
    },
    buttonClass(selected = false, outlined = false) {
      return [
        'pill-button',
        (outlined ? 'pill-button--outline' : 'pill-button--outline-hover'),
        (selected ? 'is-selected' : null)
      ].filter(Boolean).join(' ')
    },
    onClick(tab) {
      this.$emit('update-tab', tab);
    }
  }
}
</script>

<style lang="scss" scoped>
.poolbar-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 19px 20px 16px;
  transition: box-shadow 250ms ease-out;

  &.is-overlapping {
    box-shadow: 0 11px 17px -11px #4f4f4f; // 0 1px 29px 13px white;
  }
}

.poolbar-header__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h4 {
    font-weight: bold;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px black;
    font-size: 2.35rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 0.89;
    margin-bottom: 0;
  }

  & + &:not(.is-empty) {
    margin-top: 18px;
  }

  .pill-button-group {
    gap: 8px;
  }
}

.scroll-prompt {
  position: absolute;
  bottom: 0;
  bottom: auto;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 9px;
  
  .pill-button {
    box-shadow: 0px 3px 9px 0px #4f4f4f;
  }
}

$reveal-all-w: 44px;

.reveal-all {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  max-width: $reveal-all-w;
}

.reveal-all-enter,
.reveal-all-leave-to {
  max-width: 0;
  opacity: 0;
}

.reveal-all-enter-to,
.reveal-all-leave {
  max-width: $reveal-all-w;
  opacity: 1;
}

.reveal-all-enter-active {
  transition: max-width 300ms cubic-bezier(0.19, 0.32, 0, 0.97),
              opacity 250ms ease-out;
}

.reveal-all-leave-active {
  transition: max-width 300ms cubic-bezier(0.34, 0.35, 0.1, 0.99),
              opacity 250ms ease-in 50ms;
}
</style>