<template>
  <transition
    name="accordion"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <div
      v-show="expanded"
      class="accordion"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onBeforeEnter(el) {
      el.style.height = 0
      el.style.opacity = 0
    },
    onEnter(el) {
      el.style.height = `${el.scrollHeight}px`
      el.style.opacity = 1
    },
    onAfterEnter(el) {
      el.style.height = null
      el.style.opacity = null
    },
    onBeforeLeave(el) {
      el.style.height = `${el.offsetHeight}px`
      el.style.opacity = 1
    },
    onLeave(el) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          el.style.height = 0
          el.style.opacity = 0
        })
      })
    },
    onAfterLeave(el) {
      el.style.height = null
      el.style.opacity = null
    }
  }
}
</script>

<style lang="postcss">
.accordion {
  overflow: hidden;
}

// Transition
.accordion-enter,
.accordion-leave-to {
  opacity: 0;
}

.accordion-enter-active {
  transition: height 500ms cubic-bezier(0.21, 0.21, 0, 1.02),
              opacity 250ms cubic-bezier(0.47, 0.21, 0.77, 0.41) 100ms;
}

.accordion-leave-active {
  transition: height 400ms cubic-bezier(0.19, 0.19, 0, 0.99) 100ms,
              opacity 300ms cubic-bezier(0.15, 0.43, 0.12, 0.86);
}
</style>
