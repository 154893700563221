<template>
  <transition
    name="poolbar"
    v-on:enter="onEnter"
    v-on:leave="$emit('leave')"
    v-on:after-leave="onAfterLeave"
  >
    <div
      v-show="open"
      :class="[
        'poolbar',
        { 'reduced-height': reducedHeight },
        { 'transition-height': transitionHeight }
      ]"
      @keydown="onKeydown"
      :tabindex="open ? 1 : -1"
    >
      <div
        ref="inner"
        class="poolbar-inner"
        @scroll="onScroll"
      >
        <PoolbarHeader
          :tab="tab"
          :overlap="headerOverlap"
          :show-scroll-prompt="showScrollPrompt"
          @update-tab="tab => updateTab(tab)"
          @scroll-prompt-click="onScrollPromptClick"
        />

        <div class="poolbar-body">
          <transition
            name="fade-fast"
            mode="out-in"
            v-on:after-leave="onTabTransitionAfterLeave"
          >
            <div
              v-if="tab === 'public'"
              key="public"
            >
              <PlaylistsGrid
                v-if="publicPlaylists.length"
                :playlists="publicPlaylists"
                @playlist-click="onPlaylistClick"
              />
              <div v-else>
                No Curated Playlists Found
              </div>
            </div>

            <div
              v-else-if="tab === 'artists'"
              key="artists"
            >
              <PlaylistsGrid
                v-if="artistPlaylists.length"
                :playlists="artistPlaylists"
                @playlist-click="onPlaylistClick"
              />
              <div v-else>
                No Featured Artists Found
              </div>
            </div>

            <div
              v-else-if="tab === 'private'"
              key="private"
            >
              <PlaylistsGrid
                v-if="userOwnedPlaylists.length"
                :playlists="userOwnedPlaylists"
                @playlist-click="onPlaylistClick"
              />
              <div v-else>
                No Playlists Found
              </div>
            </div>

            <div
              v-else-if="tab === 'playlist'"
              key="playlist"
            >
              <div v-if="activePlaylist">
                <PlaylistHero
                  :playlist="activePlaylist"
                  class="mb-4"
                />

                <div class="mb-4">
                  <div class="pill-button-group">
                    <button
                      class="pill-button pill-button--outline pill-button--sm"
                      @click="copyShareLink"
                    >
                      Share
                      <span class="share-icon">
                        <svg width="14" height="14" viewBox="0 0 37 37" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M29.5 9C29.5 8.17157 28.8284 7.5 28 7.5H14.5C13.6716 7.5 13 8.17157 13 9C13 9.82843 13.6716 10.5 14.5 10.5L26.5 10.5L26.5 22.5C26.5 23.3284 27.1716 24 28 24C28.8284 24 29.5 23.3284 29.5 22.5V9ZM9.06066 30.0607L29.0607 10.0607L26.9393 7.93934L6.93934 27.9393L9.06066 30.0607Z"
                              fill="currentColor"/>
                        </svg>
                      </span>
                    </button>
                    <input class="sr-only" type="text" ref="playlistLinkText" v-model="playlistLink">
                  </div>
                </div>

                <div v-if="loading">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>

                <div v-else>
                  <div v-if="activePlaylist && activePlaylist.description !== null && activePlaylist.description.length > 0" class="mb-4">
                    <p class="playlist-description">{{ activePlaylist.description }}</p>
                  </div>

                  <div v-if="activePlaylist && activePlaylist.songs.length > 0">
                    <draggable
                      v-if="userHasEditPrivileges"
                      tag="ul"
                      class="song-list song-list--editable"
                      v-model="activePlaylist.songs"
                      group="playlist_songs"
                      draggable=".song-list-item"
                      handle=".drag-handle"
                      @end="doneSorting"
                    >
                        <li
                          v-for="(song, i) in activePlaylist.songs"
                          :key="i"
                          :class="[
                              'song-list-item',
                              'drag-handle',
                              { 'is-playing': song.id === activeSongId }
                          ]"
                          :style="{
                              '--color': song.hex_color
                          }"
                        >
                          <div class="song-list-item__content">
                            <div class="song-list-item__index">{{ i+1 }}.</div>
                            <div class="row">
                              <div class="col-8">
                                <div
                                  @click="onSongClick(song.id)"
                                  class="clickable"
                                  :title="song.id === activeSongId ? 'Currently playing' : 'Play song'"
                                >
                                  <SongListTitleRow
                                    :title="song.title"
                                    :active="song.id === activeSongId"
                                  />
                                  <div class="song-list-item__subtitle-row small">
                                    {{ song.artist }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="pill-button-group justify-content-end">
                                  <button @click="deleteSongFromPlaylistWithConfirmation(song)"
                                          class="pill-button pill-button--sm">
                                    Remove
                                  </button>
                                  <button v-if="activePlaylist.songs.length"
                                          type="button"
                                          class="pill-button"
                                          title="Edit song order">
                                    <span class="sr-only">Edit song order</span>
                                    <i class="fas fa-grip-lines"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                    </draggable>

                    <SongList
                      v-else
                      :songs="activePlaylist.songs"
                      @song-click="song => onSongClick(song.id)"
                    />
                  </div>
                  <div v-else>
                    <p class="text-muted text-center mt-5 mb-5" style="opacity: 0.5; font-style: italic;">No Tracks</p>
                  </div>
                </div>

                <div v-if="userHasEditPrivileges" class="text-right">
                  <hr class="my-3" style="background-color: #efefef; opacity: 1;" />
                  <button
                    class="pill-button pill-button--outline pill-button--sm"
                    @click="deletePlaylistWithConfirmation(activePlaylist)"
                    v-text="'Delete Playlist'"
                  />
                </div>
              </div>
            </div>

            <div
              v-else-if="tab === 'custom'"
              key="custom"
            >
              <CustomPlaylist
                @song-click="song => onSongClick(song.id)"
                @reset="scrollToTop(true)"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'

import PoolbarHeader from './PoolPlaylists/PoolbarHeader.vue'
import CustomPlaylist from './PoolPlaylists/CustomPlaylist.vue'
import SongList from './PoolPlaylists/SongList.vue'
import SongListTitleRow from './PoolPlaylists/SongListTitleRow.vue'
import PlaylistsGrid from './PoolPlaylists/PlaylistsGrid.vue'
import PlaylistHero from './PoolPlaylists/PlaylistHero.vue'

export default {
  name: 'PoolPlaylists',
  components: {
    draggable,
    PoolbarHeader,
    CustomPlaylist,
    SongList,
    SongListTitleRow,
    PlaylistsGrid,
    PlaylistHero
  },
  props: {
    reducedHeight: {
      type: Boolean,
      default: false
    },
    transitionHeight: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tab: 'public', // or 'private' or 'playlist' or 'custom'
    loading: false,
    headerOverlap: false,
    showScrollPrompt: false
  }),
  computed: {
    ...mapState('wavpool', {
      open: 'poolPlaylistsOpen'
    }),
    ...mapGetters('auth', [
      'user',
      'isAdmin',
      'authenticated'
    ]),
    ...mapGetters('wavpool', [
      'publicPlaylists',
      'artistPlaylists',
      'userPlaylists',
      'activeSong',
      'activePlaylist',
      'activeSongId',
      'activePlaylistHash'
    ]),
    userOwnedPlaylists() {
      return this.userPlaylists.filter(this.userOwnsPlaylist)
    },
    userIsPlaylistOwner() {
      return this.userOwnsPlaylist(this.activePlaylist)
    },
    userHasEditPrivileges() {
      return this.userIsPlaylistOwner || this.isAdmin
    },
    playlistLink() {
      if (!this.activePlaylist) return ''

      return `${window.location.host}/pools/${this.activePlaylistHash}`;
    }
  },
  methods: {
    ...mapActions('wavpool', ['AddPlaylist', 'RemovePlaylist']),
    scrollToTop(smooth = false) {
      this.$refs.inner.scrollTo({
        top: 0,
        left: 0,
        behavior: smooth ? 'smooth' : 'auto'
      })
    },
    deleteSongFromPlaylistWithConfirmation(song) {
      const playlist = {
        id: this.activePlaylist.id,
        hash: this.activePlaylist.hash,
        type: this.activePlaylist.status
      }

      Swal.fire({
        title: 'Delete track?',
        text: 'Are you sure you want to delete this track from the playlist?',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.deleteSongFromPlaylist(song, playlist)
        }
      })
    },
    deleteSongFromPlaylist(song, playlist) {
      this.loading = true

      this.$http.post('/playlists/remove-song/', {
        song: song,
        playlist: playlist
      })
        .then(response => response.data.playlist)
        .catch(err => {
          console.log(err)

          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong',
            text: 'Check the console'
          })
        })
        .then(this.AddPlaylist)
        .then(() => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Success!',
            text: 'Song was removed'
          })

          this.loading = false
        })
    },
    copyShareLink() {
      /* Get the text field */
      const copyText = this.$refs.playlistLinkText

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      this.$vs.notification({
        position: 'top-center',
        title: 'Success',
        text: 'Playlist share link has been copied to your clipboard.'
      })
    },
    doneSorting() {
      this.$http.post('/playlists/sort', {
        playlist: this.activePlaylist
      })
    },
    doDelete(playlist) {
      this.$http.get(`/playlist/delete/${playlist.id}`)
        .then(response => {
          this.RemovePlaylist(response.data.playlist.hash);

          this.$vs.notification({
            position: 'top-center',
            title: 'Success!',
            text: 'Playlist was deleted'
          })

          this.updateTab('back')

          this.$emit('playlist-deleted')
        })
        .catch(err => {
          console.log(err)

          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops!',
            text: 'Something went wrong, check the console'
          })
        })
    },
    deletePlaylistWithConfirmation(playlist) {
      Swal.fire({
        title: 'Delete Playlist?',
        text: 'Are you sure you want to delete this playlist?',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          this.doDelete(playlist);
        }
      })
    },
    onPlaylistClick({hash}) {
      this.$emit('playlist-click', hash);
    },
    onSongClick(songId) {
      this.$emit('playlist-song-click', songId);
    },
    onTabTransitionAfterLeave() {
      this.scrollToTop()
    },
    onKeydown(event) {
      const KEYBOARD_SPACE = 32;

      if (event.which === KEYBOARD_SPACE) {
        event.stopPropagation();
      }
    },
    onScroll(e) {
      this.headerOverlap = e.currentTarget.scrollTop > 8
      this.showScrollPrompt = e.currentTarget.scrollTop > 150
    },
    onScrollPromptClick() {
      this.scrollToTop(true)
    },
    updateTab(tab) {
      if (tab === 'back') {
        this.tab = this.activePlaylist && this.activePlaylist.status || 'public'
        this.$emit('click-close');
      } else {
        this.tab = tab
      }
    },
    userOwnsPlaylist(playlist) {
      return this.user.hasOwnProperty('id')
        && playlist
        && this.user.id === playlist.user_id;
    },
    onEnter() {
      this.scrollToTop()
    },
    onAfterLeave() {
      this.tab = 'public'
      this.$emit('after-leave');
    }
  },
  watch: {
    activePlaylist(newPlaylist) {
      if (newPlaylist) {
        this.tab = 'playlist'
      }
    },
    tab(newTab, oldTab) {
      if (newTab !== 'playlist') {
        this.$emit('tab-change-non-playlist')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.poolbar {
  position: fixed;
  z-index: 1;
  top: 68px;
  right: 0;
  right: env(safe-area-inset-right, 0);
  padding-right: 15px;
  width: 475px;
  max-width: calc(100vw - 30px);
  height: 800px; /* @TODO - Better screen placement */
  max-height: 85vh;
  max-height: calc(100% - 68px - 57px); // 68 for the globe icon at top, 57 for pill buttons at bottom

  &.transition-height {
    transition: max-height 0.55s cubic-bezier(0.41, 0.25, 0.1, 1);
  }

  &.reduced-height {
    max-height: calc(100% - 300px);
  }

  /* Prevent focus state from spacebar */
  &:focus {
    outline: 0;
  }
}

.poolbar-inner {
  height: 100%;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%), 0 4px 20px 10px rgb(0 0 0 / 10%);
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.poolbar-enter-active,
.poolbar-leave-active {
  transition: transform 0.55s cubic-bezier(0.41, 0.25, 0.1, 1)
}

.poolbar-enter-active {
  transition-timing-function: cubic-bezier(0.36, 0.32, 0.1, 1);
}

.poolbar-enter,
.poolbar-leave-to {
  transform: translateX(105%);
}

.poolbar-body {
  padding: 12px 28px 34px;
}

.drag-handle {
  cursor: grab;
}

.playlist-description {
  font-weight: 400;
}
</style>
