<template>
  <div class="pool-canvas" ref="canvas"></div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import * as d3 from 'd3';
import {clamp} from 'lodash';

const UPPER_BOUND_X = 10
const UPPER_BOUND_Y = 200

export default {
  name: 'PoolCanvas',
  data: function () {
    const width = Math.max(window.innerWidth, document.documentElement.clientWidth, document.body.clientWidth);
    const height = Math.max(window.innerHeight, document.documentElement.clientHeight, document.body.clientHeight);
    const transform = d3.zoomIdentity;
    const poolExtent = [2560, 1440];
    // const poolExtent = [300,100];

    const zoomInstance = d3.zoom()
      // .translateExtent([-width*2, -height*2], [width*2, height*2])
      // .extent([0, 0], [width*2, height*2])
      .scaleExtent([1, 4])
      .on('zoom', this.onZoom);

    const songCoordinates = {};

    return {
      canvas: {width, height, transform, poolExtent},
      songCoordinates: songCoordinates,
      zoomInstance
    };
  },
  mounted() {
    this.$nextTick(function () {
      d3.select(this.$refs.canvas)
        .call(this.zoomInstance)

      window.addEventListener('resize', this.onResize);
      this.onResize()
    });
  },
  beforeUpdate() {
    this.updateSongCoordinates(d3.zoomTransform(this.$refs.canvas), this.songs);
  },
  updated() {
    this.renderPool();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState('wavpool', [
      'highlightedSongsIdMap',
      'keywordCoordinates'
    ]),
    ...mapGetters('wavpool', [
      'songs',
      'activeSongId',
      'activeSong',
      'listenedTo',
      'customPlaylistActiveKeywordMood',
      'customPlaylistActiveKeywordTempo'
    ]),
    x_scale() {
      return d3.scaleLinear()
        .domain(this.x_domain)
        .range([0, this.canvas.width]);
    },
    y_scale() {
      return d3.scaleLinear()
        .domain(this.y_domain)
        .range([this.canvas.height / (1 + this.k), 0]);
    },
    x_domain() {
      const domain = [1.58, 7.4];

      const scaling = (domain[1] - domain[0]) / 2 * (1 - (this.canvas.width / 1667))

      return [domain[0] + scaling, domain[1] - scaling];

      // const [min, max] = d3.extent(this.songs, song => song.display_x);
      // return [Math.floor(min), Math.ceil(max)];
    },
    y_domain() {
      const domain = [54, 111];

      const scaling = (domain[1] - domain[0]) / 2 * (1 - (this.canvas.height / 912))

      return [domain[0] + scaling, domain[1] - scaling];

      // const [min, max] = d3.extent(this.songs, song => song.display_y);
      // return [Math.floor(min), Math.ceil(max)];
    },
    k() {
      // return this.canvas.height / this.canvas.width;
      return 0.22350914; // hardcoded perspective
    },
    poolRadius() {
      return this.canvas.width > 900 ? 0.37 : 0.55;
    },
    horizontal_bounds() {
      const poolWidth = this.canvas.width * this.poolRadius * 2;
      const poolMargin = (this.canvas.width - poolWidth) * 0.5;

      return [poolMargin, this.canvas.width - poolMargin];
    },
    vertical_bounds() {
      return [this.canvas.height, 0];
    }
  },
  methods: {
    ...mapActions('wavpool', ['SelectSong']),
    onZoom() {
      this.$forceUpdate();
    },
    updateSongCoordinates(transform, songs) {
      const x_scale = transform.rescaleX(this.x_scale);
      const y_scale = transform.rescaleY(this.y_scale);

      const display_horizontal_bounds = this.horizontal_bounds.map(x_scale.invert);
      const display_vertical_bounds = this.vertical_bounds.map(y_scale.invert);

      songs.forEach((song) => {
        this.songCoordinates[song.id] = {
          visible: song.display_x >= display_horizontal_bounds[0]
            && song.display_x <= display_horizontal_bounds[1]
            && song.display_y >= display_vertical_bounds[0]
            && song.display_y <= display_vertical_bounds[1],
          x: x_scale(song.display_x).toFixed(1),
          y: y_scale(song.display_y).toFixed(1)
        };
      });
    },
    renderPool() {
      d3.select(this.$refs.canvas)
        .selectAll('.pool-song')
        .data(this.songs, function (d) {
          return d ? d.id : this.dataset.id;
        })
        .join(
          enter => {
            return enter
              .filter(song => this.songCoordinates[song.id].visible)
              .append('div')
              .style('display', 'initial')
              .classed('pool-song', true)
              .attr('data-id', song => song.id)
              .on('click', this.onClick)
              // .style('display', song => this.songCoordinates[song.id].visible ? 'initial' : 'none')
              .classed('listened', song => this.listenedTo.hasOwnProperty(song.id))
              .classed('is-active', song => song.id === this.activeSongId)
              .classed('is-highlighted', song => this.highlightedSongsIdMap.hasOwnProperty(song.id))
              .style('--color', song => song.hex_color)
              .style('transform', song => `translate(${this.songCoordinates[song.id].x}px, ${this.songCoordinates[song.id].y}px)`);
          },
          update => {
            update
              .classed('listened', song => this.listenedTo.hasOwnProperty(song.id))
              .classed('is-active', song => song.id === this.activeSongId)
              .classed('is-highlighted', song => this.highlightedSongsIdMap.hasOwnProperty(song.id))

            update
              .filter(song => !this.songCoordinates[song.id].visible)
              .style('display', 'none')

            const visible = update
              .filter(song => this.songCoordinates[song.id].visible)
              .style('display', 'initial');

            visible
              .filter(':not(.is-active)')
              // .transition()
              // .duration(150)
              // .ease(d3.easeCubicOut)
              .style('transform', song => `translate(${this.songCoordinates[song.id].x}px, ${this.songCoordinates[song.id].y}px)`)

            visible.filter('.is-active')
              // .transition()
              // .duration(150)
              // .ease(d3.easeCubicOut)
              .style('transform', song => `translate(${this.songCoordinates[song.id].x}px, ${this.songCoordinates[song.id].y}px) scale(1.8)`);

            return visible.selection();
          }
        )
    },
    panTo({ display_x, display_y }, animate = false) {
      const currentTransform = d3.zoomTransform(d3.select(this.$refs.canvas).node())

      const x = clamp(parseFloat(display_x), 0, UPPER_BOUND_X)
      const y = clamp(parseFloat(display_y), 0, UPPER_BOUND_Y)

      console.log('panning', [x, y]);

      let transX = x >= 0 ? (this.canvas.width / 2) - 8 - this.x_scale(x) : currentTransform.x
      let transY = y >= 0 ? (this.canvas.height / 2) - 8 - this.y_scale(y) : currentTransform.y

      d3.select(this.$refs.canvas)
        .transition()
        .duration(animate ? 500 : 0)
        .call(this.zoomInstance.transform, d3.zoomIdentity.translate(transX, transY))
    },
    locateSong(song) {
      this.panTo(song, true)
    },
    locateKeyword(keyword) {
      console.log('locate keyword');
      if (!keyword) return

      const coordinates = this.keywordCoordinates[keyword.id]

      console.log(coordinates);
      if (coordinates) {
        const [display_x, display_y] = coordinates

        this.panTo({ display_x, display_y }, true)
      }
    },
    onClick(e) {
      const clicked_id = parseInt(e.target.dataset.id);

      const song = this.songs.find((song) => clicked_id === song.id);

      this.SelectSong(song)
        .then(() => {
          this.$emit('song-selected');
          this.$forceUpdate();
        });
    },
    onResize() {
      this.canvas.width = this.$refs.canvas.clientWidth;
      this.canvas.height = this.$refs.canvas.clientHeight;

      this.$forceUpdate();
    }
  },
  watch: {
    highlightedSongsIdMap() {
      this.$nextTick(() => this.$forceUpdate())
    },
    songs: function () {
      this.$nextTick(() => this.$forceUpdate())
    },
    activeSong: function (song) {
      if (song && song.locate) {
        this.locateSong(song);
      }

      this.$nextTick(() => this.$forceUpdate())
    },
    customPlaylistActiveKeywordMood(mood) {
      this.locateKeyword(mood)
    },
    customPlaylistActiveKeywordTempo(tempo) {
      this.locateKeyword(tempo)
    }
  }
}
</script>
<style lang="scss">
$z-active: 1;
$z-hover: $z-active + 1;

.pool-canvas {
  // Adding this 20px overflow to trick D3
  // otherwise it tries to hide songs before they go outside the edge of the element
  top: -20px;
  left: -20px;
  height: calc(100vh + 40px);
  width: calc(100vw + 40px);
  overflow: hidden;
  position: relative;
  background-color: #111;
  color: white;
}

.pool-song {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  pointer-events: all;
  transition: background-color 300ms ease-out;

  &:hover {
    z-index: $z-hover;
  }

  &.listened:not(.is-active) {
    background-color: var(--color);
  }

  @media (hover: hover) {
    &.listened:not(.is-active):hover {
      background-color: var(--color);
      transition-duration: 120ms;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
  }

  // Rounded square
  &:before {
    z-index: 0;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 1px;
    border: 1px solid white;
    box-shadow: 0 0 2px 0 white,
          inset 0 0 2px 0 white;
    transition: border-color 300ms ease-out,
          box-shadow 300ms ease-out;
  }

  // Shadow
  &:after {
    z-index: -1;
    height: 40px;
    width: 40px;
    background: var(--color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-filter: blur(6px);
    filter: blur(6px);
    border-radius: 50%;
    box-shadow: 0 0 37px 3px var(--color);
    opacity: 0;
    transition: opacity 700ms cubic-bezier(0.2, 0.16, 0.12, 0.94);
  }

  // Highlighted = belonging to currently selected playlist
  &.is-highlighted:before {
    box-shadow: 0 0 3px 0 var(--color),
          inset 0 0 3px 0 var(--color);
  }

  &.is-highlighted.listened:before,
  &.is-highlighted.is-active:before {
    box-shadow: 0 0 3px 0 white,
          inset 0 0 3px 0 white;
  }

  &.is-highlighted:not(.is-active):before {
    border-color: var(--color);
  }

  @media (hover: hover) {
    &:hover:not(.is-active):before {
      border-color: var(--color);
      box-shadow: 0 0 3px 0 var(--color),
            inset 0 0 3px 0 var(--color);
      transition-duration: 120ms;
    }
  }

  /* active = currently being listened to */
  &.is-active {
    z-index: $z-active;
    transition-duration: 350ms;
    transition-timing-function: cubic-bezier(0.2, 0.62, 0.12, 0.94);

    &:after {
      opacity: 0.85;
    }
  }

  &.listened:not(.is-active):before {
    border-color: var(--color);
    box-shadow: 0 0 3px 0 var(--color),
    inset 0 0 3px 0 var(--color);
  }
}
</style>
