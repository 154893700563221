<template>
  <div
    :class="[
      'pool-annotation',
      `pool-annotation--${number}`
    ]">
    <img
      v-if="number > 0"
      :src="`/img/annotation-${number}.png`"
    />

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss">
$desktop-screen-width: 900px;

.pool-annotation {
  position: absolute;
  z-index: 1000; // Above all the content??
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4); 

  padding-left: 15px;
  padding-right: 15px;
  
  @media (min-width: $desktop-screen-width) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  img {
    display: block;
    height: 90%;
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
    max-height: 850px;
    max-width: 850px;
    pointer-events: none;
  }

  &--1 {
    padding-left: 5px;
    padding-right: 458px;

    @media (min-width: $desktop-screen-width) {
      padding-right: 480px;
      padding-right: max(480px, 29vw);      
    }

    img {
      margin-right: 0;
      object-position: right;
    }
  }

  &--2,
  &--3 {
    @media (min-width: $desktop-screen-width) {
      padding-right: 23vw;
      padding-left: 23vw;
    }
  }
}
</style>