<template>
      <div
        v-show="show"
        class="pool-splash-layer"
      >
        <transition
          name="splash-overlay"
          v-on:enter="onEnter"
          v-on:after-leave="onAfterLeave"
        >
          <div 
            v-if="loaded" 
            v-show="show && imagesLoaded == 4" 
            class="pool-splash" 
            ref="overlay" 
            style="{
              'height': `${overlayHeight}px`
            }"
          >
              <a
                href="#"
                class="pill-button pill-button--outline"
                @click.prevent="onLeave()"
              >
                CLOSE
              </a>
              <div class="pool-splash__inner">
                <div 
                  class="pool-splash__inner-left-column clickable"
                  @click="$emit('playlist-click', links.main.playlist_hash)"
                >
                  <img :src="links.main.image" @load="imagesLoaded++">
                </div>

                <div class="pool-splash__inner-right-column">
                  <div 
                    class="pool-splash__inner-right-row clickable"
                    @click="$emit('playlist-click', links.top_right.playlist_hash)"
                  >
                    <img :src="links.top_right.image" @load="imagesLoaded++">
                  </div>

                  <div 
                    class="pool-splash__inner-right-row clickable"
                    style="padding: 1px 0 1px 0"
                    @click="$emit('playlist-click', links.middle_right.playlist_hash)"
                  >
                    <img :src="links.middle_right.image" @load="imagesLoaded++">
                  </div>
                  
                  <div 
                    class="pool-splash__inner-right-row clickable"
                    @click="$emit('playlist-click', links.bottom_right.playlist_hash)"
                  >
                    <img :src="links.bottom_right.image" @load="imagesLoaded++">
                  </div>
                </div>
              </div>
          </div>
        </transition>
      </div>
</template>

<script>
export default {
  name: 'SplashOverlay',
  props: [ 'show', 'links', 'loaded' ],
  data: function() {
    return {
      overlayWidth: null,
      imagesLoaded: 0
    }
  },
  methods: {
    onResize() {
      this.overlayWidth = this.$refs.overlay.clientWidth
    },
    onEnter() {
      this.overlayWidth = this.$refs.overlay.clientWidth
      window.addEventListener('resize', this.onResize)
    },
    onLeave() {
      this.$emit('close-click')
    },
    onAfterLeave() {
      this.$emit('after-leave')
    },
    loadSplashLinks() {
      this.$http.get('/splashlinks')
        .then(response => {
          this.splashLinks = response.data.splash_links
          this.loaded.links = true
          if (this.validateSplashLinks()) {
            this.loaded.links = true
          } else {
            this.$emit('not-valid')
          }
        })
    },
    validateSplashLinks() {
      let valid = true
      Object.values(this.splashLinks).forEach(value => {
        valid = Object.values(value).every(v => {
          if (v !== null && v != '') return true
          return false
        })
      });
      
      return valid
    }
  },
  computed: {
    overlayHeight() {
      return this.overlayWidth * (0.44)
    }
  }
}
</script>

<style lang="scss" scoped>
.splash-overlay-enter,
.splash-overlay-leave-to {
  opacity: 0;
}

.splash-overlay-enter-active,
.splash-overlay-leave-active {
  transition: opacity .35s cubic-bezier(0.38, 0.18, 0.9, 0.63);
}

.pool-splash-layer {
  position: absolute;
  z-index: 1000; // Above all the content??
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 458px;
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.4);
}
.pool-splash {
  position: absolute;
  width: 75vw;
  z-index: 1001;
  left: 50%;
  top: calc(50% - 20px);
  transform: translate(-50%,-50%);
  text-align: right;
}

.pool-splash__inner {
  background: white;
  height: 100%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  padding: 1.5px; 
}

.pool-splash__inner-left-column {
  flex: 66.6666%;
  width: 66.6666%;
  height: 100%;
  margin-top: 1px;
  padding: 0px 1.5px 0px 0.5px;
}

.pool-splash__inner-right-column {
  flex: 33.3333%;
  width: 33.3333%;
  height: 100%;
}

.pool-splash__inner-right-row {
  width: 100%;
  height: 33.3333%;
}

.pool-splash__inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>